// const BaseURL = "http://applucc.webzeminiprint.in/api";
const BaseURL = "https://luccapp.com/api";
const apis = {
  fortuneWheel: {
    wheelValue: `${BaseURL}/Game/GamePoint`,
    winValue: (MemberId, winValue) => `${BaseURL}/Game/CollectGamePoint?MBR_ID=${MemberId}&win=${winValue}`,
    wheelTiming: (id) => `${BaseURL}/Game/Wheel_Enable_Disable?MBR_ID=${id}`,
  },
};

export default apis;
